import Axios from "axios";

export default {
  state: {
    token: localStorage.getItem("token") || null,
    userDetails: localStorage.getItem("userDetails") || '{}',
    availableLocations: localStorage.getItem("availableLocations") || '[]',
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    invalidateToken(state) {
      state.token = null;
      localStorage.removeItem("token");
    },
    setUserDetails(state, userDetails) {
      state.userDetails = JSON.stringify(userDetails);
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    },
    setAvailableLocations(state, availableLocations) {
      state.availableLocations = JSON.stringify(availableLocations);
      localStorage.setItem("availableLocations", JSON.stringify(availableLocations));
    },
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      await Axios.post(
        `${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/login`,
        payload
      )
        .then((result) => {
          // Set the user token
          commit("setToken", result.data.token);
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
        dispatch("fetchUserDetails");
    },
    async logout({ commit }) {
      await Axios.post(`${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/logout`)
        .then(() => {
          // Remove the user token
          commit("invalidateToken");
        })
        .catch((err) => {
          console.log(err);
          commit("invalidateToken");
          throw err;
        });
        commit("setUserDetails", {});
        commit("setAvailableLocations", []);
    },
    async sendPasswordResetRequest(_, payload) {
      await Axios.post(`${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/reset-password`, payload)
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
    async resetPassword(_, payload) {
      await Axios.put(
        `${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/reset-password/${payload.token}`,
        { password: payload.password }
      )
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
    async fetchAvailableLocations({ commit }) {
       return await Axios.get(`${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/available-locations`)
        .then((response) => {
          commit("setAvailableLocations", response.data);
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
    async updateUserDetails({ commit }, payload) {
      // Add to database
      await Axios.put(
        `${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/user`,
        payload
      )
      .catch((err) => {
        console.log(err);
        throw err;
      });
      // Add to local storage
      commit("setUserDetails", { ...this.getters.getUserDetails, ...payload})
    },
    async fetchUserDetails({ commit }) {
      return await Axios.get(
        `${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/user`
      ).then((result) => {
        commit("setUserDetails", result.data);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    },
  },
  getters: {
    getToken: (state) => state.token,
    getUserDetails: (state) => JSON.parse(state.userDetails),
    getAvailableLocations: (state) => JSON.parse(state.availableLocations)
  },
};
