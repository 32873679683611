<template>
  <v-container fluid class="pa-3">
    <ActionDialog
      ref="confirm_finish_dialog"
      title="Items Remaining"
      max_width="90%"
      color="black"
      persistent
      cancel_text="Cancel"
    >
      <template #default="{ options }">
        <v-card-text>
          Some items have not been picked, are you sure you want to continue?
        </v-card-text>
      </template>
    </ActionDialog>
    <!-- TODO REMOVE THIS ACTION DIALOG ONCE NO LONGER USING OLD SERIALIZED PRODUCTS -->
    <ActionDialog
      ref="old_serial_dialog"
      title="Enter Serial Number"
      max_width="90%"
      color="black"
      persistent
      cancel_text="Cancel Item Pick"
    >
      <template #default="{ options }">
        <v-text-field
          id="old_serial_number_input"
          v-model="options.data.serial_number"
          label="Serial Number"
          placeholder="Please enter a serial number"
          variant="outlined"
          hide-details="auto"
          clearable
          persistent-clearable
          :rules="[
            (v) => !!v || 'Required',
            (v) => v !== 'Enter' || 'Invalid serial number',
          ]"
          @keypress.enter="playSuccessBeep"
          virtualkeyboardpolicy="auto"
          @keyup.enter="deselectInput()"
        ></v-text-field>
      </template>
    </ActionDialog>
    <ActionDialog
      ref="serial_dialog"
      title="Enter Serial Number"
      max_width="90%"
      color="black"
      persistent
      cancel_text="Cancel Item Pick"
    >
      <template #default="{ options }">
        <v-text-field
          id="serial_number_input"
          v-model="options.data.serial_number"
          label="Serial Number"
          placeholder="Please enter a serial number"
          variant="outlined"
          hide-details="auto"
          clearable
          persistent-clearable
          :rules="[
            (v) => !!v || 'Required',
            (v) => (v !== 'Enter' && available_serial_numbers.includes(v)) || 'Invalid serial number',
          ]"
          @keypress.enter="playSuccessBeep"
          virtualkeyboardpolicy="auto"
          @keyup.enter="deselectInput()"
        ></v-text-field>
      </template>
    </ActionDialog>
    <ActionDialog
      ref="details_dialog"
      title="Order Details"
      max_width="90%"
      :cancellable="false"
    >
      <v-row>
        <v-col v-for="sales_order in $store.getters.getSalesOrderNumbers" align="center">
          <b>{{ sales_order }}</b>
        </v-col>
      </v-row>
    </ActionDialog>
    <ActionDialog
      ref="created_fulfillments"
      title="Results"
      max_width="90%"
      :cancellable="false"
      :persistent="true"
    >
      <v-row v-if="result.shipments.valid.length != 0">
        <v-col>
          <h3>Created Fulfillments</h3>
        </v-col>
      </v-row>
      <v-row v-if="result.shipments.valid.length != 0">
        <v-col v-for="shipment in result.shipments.valid" align="center">
          {{ shipment.fulfillmentNumber }}
        </v-col>
      </v-row>
      <v-row v-if="result.shipments.invalid.length != 0">
        <v-col>
          <h3>Failed Orders</h3>
        </v-col>
      </v-row>
      <v-row v-if="result.shipments.invalid.length != 0">
        <v-col v-for="shipment in result.shipments.invalid" align="center">
          {{ shipment.salesOrderNumber }}
        </v-col>
      </v-row>
    </ActionDialog>
    <v-row id="header">
      <v-col align="center">
        <h1>Pick Items</h1>
      </v-col>
    </v-row>
    <v-row id="header_action">
      <v-col align="center">
        <v-btn block size="large" @click="showOrderDetails()">
          Order Details
        </v-btn>
      </v-col>
    </v-row>
    <v-row id="input">
      <v-col align="center">
        <v-text-field
          id="item_sku_barcode_input"
          v-model="item_sku_barcode"
          type="text"
          label="SKU/Barcode"
          placeholder="Please enter a SKU or Barcode"
          variant="outlined"
          hide-details
          clearable
          persistent-clearable
          virtualkeyboardpolicy="manual"
          ondblclick="navigator.virtualKeyboard.show()"
          @blur="hideKeyboard()"
          contenteditable
          @keyup.enter="onEnter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row id="alert">
      <v-col>
        <TempAlert ref="alert" />
      </v-col>
    </v-row>
    <v-row id="list">
      <v-col>
        <v-card :loading="this.$store.getters.getLoading">
          <v-card-title>Products To Pick</v-card-title>
          <v-divider />

          <v-card-text class="pa-0" style="overflow: auto" :style="height">
            <v-list lines="three" class="pa-0">
              <template v-for="(item, i) in items" :key="i">
                <v-list-item @click="goToPicklistItem(item)">
                  <template #prepend>
                    <v-sheet
                      class="notification-status-left"
                      :style="`color: ${getItemCardColor(item)}`"
                    ></v-sheet>
                    <v-img
                      :src="$store.getters.getProductImage(item.sku)"
                      gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)"
                      height="56px"
                      width="56px"
                      class="image-border mr-3"
                      contain
                    ></v-img>
                  </template>

                  <v-list-item-title>
                    <b>{{ item.name }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    SKU: {{ item.sku }}
                  </v-list-item-subtitle>
                  <!-- <v-list-item-subtitle>
                    Barcode: {{ item.barcode }}
                  </v-list-item-subtitle> -->
                  <v-list-item-subtitle>
                    Location: {{ item.pickPathLocation }}
                  </v-list-item-subtitle>
                  <template #append>
                    <v-row>
                      <v-col align="center">
                        {{ item.count }} / {{ item.quantity }}
                      </v-col>
                    </v-row>
                    <v-sheet
                      class="notification-status-right"
                      :style="`color: ${getItemCardColor(item)}`"
                    ></v-sheet>
                  </template>
                </v-list-item>
                <v-divider v-if="i < items.length - 1" />
              </template>

              <v-list-item v-if="items.length === 0 && this.$store.getters.getLoading">
                <v-list-item-title>🥁 Items loading...</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="items.length === 0 && !this.$store.getters.getLoading">
                <v-list-item-title>🎉 All items picked!</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row id="action" class="action_button">
      <v-col align="center">
        <v-btn
          block
          size="large"
          :loading="this.$store.getters.getLoading"
          :disabled="picked_items.length === 0 || this.$store.getters.getLoading || has_over_picked_items || ship_complete_not_respected"
          @click="onFinish"
        >
          Finish
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import success_beep from "@/assets/success_beep.mp3";
import error_beep from "@/assets/error_beep.mp3";
import notify_beep from "@/assets/notify_beep.mp3";
import ActionDialog from "@/components/dialogs/ActionDialog";
import TempAlert from "@/components/alerts/TempAlert";
export default {
  components: {
    ActionDialog,
    TempAlert,
  },
  data() {
    return {
      item_sku_barcode: "",
      selected_input: "item_sku_barcode_input",
      error_audio: new Audio(error_beep),
      notify_audio: new Audio(notify_beep),
      success_audio: new Audio(success_beep),
      list_max_height: 0,
      can_select: false,
      available_serial_numbers: [],
      result: {},
      blur_by_code: false
    };
  },
  beforeDestroy() {
    navigator.virtualKeyboard.overlaysContent = false;
  },
  created() {
    navigator.virtualKeyboard.overlaysContent = true;
    document.addEventListener("keydown", this.scanPressed);
    document.addEventListener("keyup", this.scanReleased);
    document.addEventListener("keypress", this.selectInput);
    document.addEventListener("paste", this.pasteInput);
  },
  mounted() {
    // Calculate the max height of the list card
    let height = window.innerHeight - 20;
    height -= document.getElementById("header").clientHeight;
    height -= document.getElementById("header_action").clientHeight;
    height -= document.getElementById("input").clientHeight;
    height -= document.getElementById("alert").clientHeight;
    height -= document.getElementById("list").clientHeight;
    height -= document.getElementById("action").clientHeight;
    this.list_max_height = height;

    // If page reloaded, refetch the item data
    this.$nextTick(async () => {
      if (this.items.length === 0) {
        try {
          await this.$store.dispatch("getPickLists");
        } catch (error) {
          this.$router.push("/scan-pick/select");
        }
      }
    });

    // Timeout to mitigate immediate selection of input field
    // when using 'Single Picklist' mode
    setTimeout(() => {
      this.can_select = true;
    }, 500);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.scanPressed);
    document.removeEventListener("keyup", this.scanReleased);
    document.removeEventListener("keypress", this.selectInput);
    document.removeEventListener("paste", this.pasteInput);
  },
  computed: {
    record_serial_number() {
      return this.$store.getters.getRequiresFulfillment || this.$store.getters.getUserDetails.generate_fulfillment;
    },
    all_items_picked() {
      return this.items.filter(item => item.count != item.quantity).length == 0;
    }, 
    ship_complete_not_respected() {
      return this.$store.getters.getShipComplete && this.items.filter(item => item.count != item.quantity).length != 0;
    },
    has_over_picked_items() {
      return this.items.filter(item => item.count > item.quantity).length;
    },
    items() {
      return this.$store.getters.getPicklistItems;
    },
    items_map() {
      return this.$store.getters.getPicklistItemsMap;
    },
    picklist_serialized_items() {
      return this.$store.getters.getPicklistItemSerialNumbers;
    },
    picked_items() {
      return this.$store.getters.getPickedItems;
    },
    height() {
      return `max-height: ${this.list_max_height}px`;
    },
  },
  methods: {
    hideKeyboard() {
      // Only Hidekeyboard if Blur was handled
      // by clicking out of input field.
      if (!this.blur_by_code) {
        const inputField = document.getElementById("hide_keyboard");
        setTimeout(function() {
          inputField.setAttribute('style', 'display:block;');
          inputField.focus();
          setTimeout(function() {
            inputField.setAttribute('style', 'display:none;');
          }, 10);
        }, 10);
      }
    },
    showOrderDetails() {
      navigator.vibrate(100);
      this.$refs.details_dialog.showDialog();
    },
    getItemCardColor(item) {
      if (item.count > item.quantity) {
        return 'red';
      } else if (item.count === item.quantity) {
        return 'green';
      } else {
        return 'white';
      }
    },
    scanPressed(e) {
      // If key pressed is the 'scanner' button
      if (e.key === "Unidentified" && e.code === "" && e.keyCode === 0 && this.can_select) {
        this.can_select = false;
        this.selectInput(e);
      }
    },
    scanReleased(e) {
      // If key pressed is the 'scanner' button
      if (e.key === "Unidentified" && e.code === "" && e.keyCode === 0) {
        this.can_select = true;
      }
    },
    selectInput(e) {
      if (e.target.tagName !== "INPUT" || e.target.id !== this.selected_input) {
        var input = document.getElementById(this.selected_input);
        input.focus();
        if (!["Enter", "Unidentified"].includes(e.key)) {
          input._assign(input.value + e.key);
        }
        e.preventDefault();
      }
    },
    deselectInput() {
      var input = document.getElementById(this.selected_input);
      this.blur_by_code = true;
      input.blur();
      this.blur_by_code = false;
    },
    pasteInput(e) {
      if (e.target.tagName !== "INPUT" || e.target.id !== this.selected_input) {
        var input = document.getElementById(this.selected_input);
        let paste = (e.clipboardData || window.clipboardData).getData("text");
        input._assign(paste);
        this.onEnter();
        e.preventDefault();
      }
    },
    openSerialNumberDialog(item_sku) {
      // Set available serial numbers for product
      this.available_serial_numbers = this.$store.getters.getPicklistItemSerialNumbers[item_sku];
      // Set the selected input to the serial number field
      this.selected_input = "serial_number_input";
      // Open the serial number dialog
      return this.$refs.serial_dialog
        .showDialog({ serial_number: "" })
        .then((data) => {
          // Set the selected input back to the sku/barcode field
          this.selected_input = "item_sku_barcode_input";
          return data.serial_number;
        }).catch(() => {
          this.selected_input = "item_sku_barcode_input";
          return;
        })
    },
    // TODO REMOVE BELOW ONCE NO LONGER USING OLD SERIALIZED PRODUCTS
    openOldSerialNumberDialog() {
      // Set the selected input to the serial number field
      this.selected_input = "old_serial_number_input";
      // Open the serial number dialog
      return this.$refs.old_serial_dialog
        .showDialog({ serial_number: "" })
        .then((data) => {
          // Set the selected input back to the sku/barcode field
          this.selected_input = "item_sku_barcode_input";
          return data.serial_number;
        }).catch(() => {
          this.selected_input = "item_sku_barcode_input";
          return;
        })
    },
    openConfirmFinishDialog() {
      return this.$refs.confirm_finish_dialog
        .showDialog()
        .then(() => {
          return true;
        }).catch(() => {
          return false;
        })
    },
    goToPicklistItem(item) {
      if (this.items_map[item.sku] !== undefined) {
        navigator.vibrate(100);
        this.$router.push(`/scan-pick/edit/${item.sku}`);
      }
    },
    async onEnter() {
      if (this.item_sku_barcode !== "") {
        if (this.items_map[this.item_sku_barcode] !== undefined) {
          // Initialize the item to a variable for easy access
          let item = this.items_map[this.item_sku_barcode];
          // Create a picked item to add to picked item list
          let picked_item = {
            sku: item.sku,
            barcode: item.barcode,
            serial_number: null,
            old_serial_number: null, // TODO REMOVE ONCE NO LONGER USING OLD SERIALIZED
          }

          // If the item is serialized
          if (this.items_map[this.item_sku_barcode].serialized === true && this.record_serial_number) {
            this.deselectInput();
            this.item_sku_barcode = "";
            this.notify_audio.play();
            // Open the serial number dialog and return the serial number
            let serial_number = await this.openSerialNumberDialog(item.sku);
            this.notify_audio.load();
            if (serial_number === undefined) {
              return;
            }
            picked_item.serial_number = serial_number;

            // Remove serial number from available serial numbers
            this.$store.getters.getPicklistItemSerialNumbers[item.sku] = this.$store.getters.getPicklistItemSerialNumbers[item.sku].filter((serial_number) => serial_number !== picked_item.serial_number);
          } // TODO REMOVE THIS WHOLE SECTION ONCE NO LONGER USING OLD SERIALIZED
          else if (this.items_map[this.item_sku_barcode].old_serialized === true && this.record_serial_number) {
            this.deselectInput();
            this.notify_audio.play();
            // Open the serial number dialog and return the serial number
            let serial_number = await this.openOldSerialNumberDialog();
            this.notify_audio.load();
            if (serial_number === undefined) {
              return;
            }
            picked_item.old_serial_number = serial_number;
          }
          // Increment the count of this item
          item.count++;
          picked_item.sales_order = item.salesOrderNumbers.pop()

          if (item.count > item.quantity) {
            this.error_audio.play();
          }
          // else {
          //   this.success_audio.play();
          // }

          // Add picked item to the picked items array
          this.$store.commit("addPickedItem", picked_item);
          // Reorder List if item is now fully picked or over picked
          if (item.count == item.quantity || item.count > item.quantity) {
            this.$store.dispatch('reorderPicklistItems')
          }
        } else {
          this.error_audio.play();
          this.$refs.alert.showAlert({ text: "SKU/Barcode not found on this order." });
        }
        this.item_sku_barcode = "";
        this.deselectInput();
      }
      this.deselectInput();
    },
    async onFinish() {
      // Confirm user is wanting to finish.
      if (this.all_items_picked || await this.openConfirmFinishDialog()) {
        // Only generate the fulfillment if user is set to
        if (this.record_serial_number) {
          this.result = await this.$store.dispatch("createItemFulfillments");
          this.$store.commit("setSalesOrderNumbers", []);
          this.$refs.created_fulfillments.showDialog().then(() => {
            this.$router.push("/scan-pick/select");
          })
        } else { // Otherwise go to pick screen again
          this.$store.commit("setSalesOrderNumbers", [])
          this.$router.push("/scan-pick/select");
        }
      }
    },
    onCancel() {
      this.$router.push("/scan-pick/select");
    },
    playSuccessBeep() {
      // this.success_audio.play();
    },
  },
};
</script>

<style>
.notification-status-left {
  position: absolute;
  left: 0;
  border: 0.19em solid;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
}
.notification-status-right {
  position: absolute;
  right: 0;
  border: 0.19em solid;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
}
.v-list-item--two-line .v-list-item__append,
.v-list-item--three-line .v-list-item__append {
  align-self: center;
}
</style>
