<template>
  <v-container fluid>
    <v-row>
      <v-col align="center">
        <h1>Main Menu</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block size="x-large" @click="goToScanPick">Scan Pick</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          size="x-large"
          :loading="loading.images"
          @click="onDownloadImages"
        >
          Download Images
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block size="x-large" :loading="loading.logout" @click="onLogout">
          Logout
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block size="x-large" :loading="loading.logout" @click="goToManageProfile">
          Manage Profile
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {
      loading: {
        images: false,
        logout: false,
      },
    };
  },
  methods: {
    goToScanPick() {
      navigator.vibrate(100);
      this.$store.commit("setSalesOrderNumbers", []);
      this.$router.push("/scan-pick/select");
    },
    async onDownloadImages() {
      navigator.vibrate(100);
      this.loading.images = true;
      await this.$store.dispatch("getProductImages");
      navigator.vibrate(300);
      this.loading.images = false;
    },
    async onLogout() {
      navigator.vibrate(100);
      this.loading.logout = true;
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/login");
      } catch (err) {
        this.$router.push("/login");
      }
      this.loading.logout = false;
    },
    goToManageProfile() {
      navigator.vibrate(100);
      this.$router.push("/profile");
    }
  },
};
</script>
