<template>
  <PickSalesOrders />
</template>

<script>
import PickSalesOrders from "@/components/PickSalesOrders.vue";
export default {
  components: {
    PickSalesOrders,
  },
};
</script>
