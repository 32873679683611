<template>
  <ResetPasswordRequest />
</template>

<script>
import ResetPasswordRequest from "@/components/ResetPasswordRequest.vue";

export default {
  components: {
    ResetPasswordRequest,
  },
};
</script>
