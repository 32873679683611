<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    :persistent="persistent"
    @click:outside.stop
    @keydown.esc="show = false"
  >
    <v-card class="rounded-lg">
      <slot name="title">
        <v-card-title>
          {{ title }}
        </v-card-title>
      </slot>
      <v-divider />

      <v-card-text class="pa-4">
        <v-form ref="dialog_form" @submit.stop.prevent>
          <slot v-bind:options="options" />
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <slot name="actions" v-bind:options="options">
          <v-spacer />
          <v-btn
            v-if="cancellable"
            class="rounded-lg"
            color="grey darken-1"
            text
            @click="options.cancel()"
            >{{ cancel_text }}</v-btn
          >
          <v-btn
            class="rounded-lg"
            outlined
            :color="color"
            @click="options.confirm(options.data)"
          >
            {{ confirm_text }}
          </v-btn>
          <v-spacer />
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },
    color: {
      type: String,
      default: "black",
    },
    max_width: {
      type: String,
      default: "35%",
    },
    cancellable: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    confirm_text: {
      type: String,
      default: "Confirm",
    },
    cancel_text: {
      type: String,
      default: "Cancel",
    },
  },
  data() {
    return {
      show: false,
      options: {
        data: {},
        confirm: null,
        cancel: null,
      },
    };
  },
  methods: {
    showDialog(data = {}) {
      return new Promise((resolve, reject) => {
        const res = async (result) => {
          if ((await this.$refs.dialog_form.validate()).valid) {
            this.show = false;
            this.options = { data: data, confirm: null, cancel: null };
            resolve(result);
          }
        };
        const rej = () => {
          this.$refs.dialog_form.resetValidation();
          this.show = false;
          this.options = { data: data, confirm: null, cancel: null };
          reject();
        };
        this.options = { data: data, confirm: res, cancel: rej };
        this.show = true;
      });
    },
  },
};
</script>
