import { createRouter, createWebHistory } from "vue-router";
import MainMenuView from "../views/MainMenuView.vue";
import SelectSalesOrdersView from "../views/SelectSalesOrdersView.vue";
import PickSalesOrdersView from "../views/PickSalesOrdersView.vue";
import EditSalesOrderItemView from "../views/EditSalesOrderItemView.vue";
import LoginView from "../views/LoginView.vue";
import ResetPasswordRequestView from "../views/ResetPasswordRequestView.vue";
import ResetPasswordTokenView from "../views/ResetPasswordTokenView.vue";
import ProfileView from "../views/ProfileView.vue";
import store from "../store/index";

const authGuard = async (to, from, next) => {
  if (store.getters.getToken === null) {
    return next("/login");
  }
  next();
};

const routes = [
  {
    path: "/",
    name: "home",
    component: MainMenuView,
    beforeEnter: authGuard,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/reset-password",
    children: [
      {
        path: "",
        name: "reset-password-request",
        component: ResetPasswordRequestView,
      },
      {
        path: ":token",
        name: "reset-password-token",
        component: ResetPasswordTokenView,
      }
    ]
  },
  {
    path: "/scan-pick",
    name: "scan-pick",
    beforeEnter: authGuard,
    children: [
      {
        path: "select",
        name: "Select Sales Orders",
        component: SelectSalesOrdersView,
      },
      {
        path: "pick",
        name: "Pick Sales Orders",
        component: PickSalesOrdersView,
      },
      {
        path: "edit/:sku_barcode",
        name: "Edit Sales Order Item",
        component: EditSalesOrderItemView,
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
