<template>
  <v-container fluid class="pa-3" v-if="sent == false">
    <v-row>
      <v-col align="center">
        <h1>Forgot your password?</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <p>We'll send reset instructions to your email address</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          id="usernameOrEmailInput"
          ref="usernameOrEmailInput"
          v-model="reset_details.username_or_email"
          label="Username or email"
          placeholder="Please enter your username or email"
          variant="outlined"
          hide-details
          @keyup.enter="onEnter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row id="alert">
      <v-col>
        <TempAlert ref="alert" />
      </v-col>
    </v-row>
    <v-row class="action_button pb-14">
      <v-col align="center">
        <v-btn
          block
          size="large"
          @click="$router.push('/login')"
        >
          Back to login
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="action_button">
      <v-col align="center">
        <v-btn
          block
          size="large"
          :loading="loading"
          :disabled="!fieldsFilled"
          @click="resetPassword()"
        >
          Reset password
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

  <v-container fluid class="pa-3" v-if="sent">
    <v-row>
      <v-col align="center">
        <h1>Reset sent!</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <p>If your username or email exists, an email will be sent to you with instructions to reset your password</p>
      </v-col>
    </v-row>
    <v-row id="alert">
      <v-col>
        <TempAlert ref="alert" />
      </v-col>
    </v-row>
    <v-row class="action_button">
      <v-col align="center">
        <v-btn
          block
          size="large"
          @click="$router.push('/login')"
        >
          Back to login
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TempAlert from "@/components/alerts/TempAlert";
export default {
  name: "LoginPage",
  components: {
    TempAlert,
  },
  data() {
    return {
      reset_details: {
        username_or_email: "",
      },
      loading: false,
      show_password: false,
      sent: false,
    };
  },
  mounted() {
    if (this.$refs.usernameOrEmailInput) {
      this.$refs.usernameOrEmailInput.focus();
    }
  },
  computed: {
    fieldsFilled() {
      if (!this.reset_details.username_or_email) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onEnter() {
      if (this.fieldsFilled) {
        this.resetPassword();
      }
    },
    async resetPassword() {
      this.loading = true;
      try {
        await this.$store.dispatch("sendPasswordResetRequest", this.reset_details);
        this.sent = true;
      } catch (err) {
        if (err.code === "ERR_NETWORK") {
          err.message = "Sorry, there was a problem connecting to the network.";
        } else if (err.code === "ERR_BAD_REQUEST") {
          err.message = "Sorry, please enter a valid username and password.";
        }
        this.$refs.alert.showAlert({ text: err.message });
      }
      this.loading = false;
    },
  },
};
</script>
