<template>
  <v-expand-transition>
    <v-alert v-if="show" lines="one" :text="options.data.text" type="error">
    </v-alert>
  </v-expand-transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      options: {
        data: {},
        confirm: null,
      },
      timeout: null,
    };
  },
  methods: {
    async showAlert(data = {}, timeout = 5000) {
      return new Promise((resolve) => {
        const res = async (result) => {
          this.show = false;
          this.options = { data: data, confirm: null, cancel: null };
          resolve(result);
        };
        this.options = { data: data, confirm: res };
        this.show = true;
        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(res, timeout);
      });
    },
  },
};
</script>
