<template>
  <v-app-bar>
    <v-img
      :src="require('../assets/macgear.png')"
      alt="Scanner Logo"
      height="90%"
      style="cursor: pointer"
      @click.stop="homeButtonPress()"
    />
  </v-app-bar>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {
      logo_url:
        "https://res.cloudinary.com/breezy-software/image/upload/v1654818317/assets/Breezy_Logo.png",
    };
  },
  methods: {
    homeButtonPress() {
      navigator.vibrate(100);
      this.$router.push('/').catch(() => {})
    }
  }
};
</script>
