<template>
  <v-container fluid class="pa-3">
    <ActionDialog
      ref="fulfullment_required_dialog"
      title="Fulfillment Required."
      max_width="90%"
      color="black"
      :cancellable="false"
      persistent
    >
      <template #default="{ options }">
        <v-card-text>
          There are items on this order that require fulfillment generation.<br/><br/>
          On completion a fulfillment will be generated.
        </v-card-text>
      </template>
    </ActionDialog>
    <ActionDialog
      ref="error_dialog"
      title="Invalid Sales Orders"
      max_width="90%"
      confirm_text="Continue"
    >
      <template #default="{ options }">
        <b>{{ options.data.message }}</b>
      </template>
    </ActionDialog>
    <v-dialog
      v-model="mode_dialog"
      persistent
    >
      <v-card class="rounded-lg">
        <v-card-title style="text-align: center">
          <span class="text-h5">Select your picking mode</span>
        </v-card-title>
        <v-divider/>
        <v-card-text style="text-align: center" class="pa-4">
          <v-row>
            <v-col cols="12" sm="12">
              <v-btn size="large" @click="setMode('single_pick')">
                Single Order
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-btn size="large" @click="setMode('multi_pick')">
                Multi Order
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row id="header">
      <v-col align="center">
        <h1 class="text-capitalize">{{ mode === '' ? 'Item' : mode.replace('_', ' ') }} Sales Order</h1>
      </v-col>
    </v-row>
    <v-row id="input">
      <v-col>
        <v-text-field
          id="sales_order_number_input"
          v-model="sales_order_number"
          label="Sales Order Number"
          placeholder="Please enter a Sales Order Number"
          variant="outlined"
          hide-details
          virtualkeyboardpolicy="manual"
          ondblclick="navigator.virtualKeyboard.show()"
          @blur="hideKeyboard()"
          contenteditable
          @keyup.enter="onEnter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row id="alert">
      <v-col>
        <TempAlert ref="alert" />
      </v-col>
    </v-row>
    <v-row id="list">
      <v-col>
        <v-card>
          <v-card-title>Selected Sales Order{{ mode == 'single_pick' ? '' : 's' }}</v-card-title>
          <v-divider />

          <v-card-text class="pa-0" style="overflow: auto" :style="height">
            <v-list lines="three" class="pa-0" id="selected_sales_orders">
              <template v-for="(salesorder, i) in sales_order_numbers" :key="i">
                <v-list-item>
                  <v-list-item-title :key="i">
                    Sales Order: <b>{{ salesorder }}</b>
                  </v-list-item-title>
                  <template #append>
                    <v-row>
                      <v-col align="center">
                        <v-btn
                          icon
                          size="large"
                          variant="outlined"
                          @click="$store.commit('removeSalesOrderNumber', i)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-list-item>
                <v-divider v-if="i < sales_order_numbers.length - 1" />
              </template>

              <v-list-item v-if="sales_order_numbers.length === 0">
                <v-list-item-title>
                  📭 No Sales Orders Selected...
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row id="action" class="action_button">
      <v-col align="center">
        <v-btn
          block
          size="large"
          :loading="loading"
          :disabled="sales_order_numbers.length === 0"
          @click="onConfirm"
        >
          Confirm
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import success_beep from "@/assets/success_beep.mp3";
import error_beep from "@/assets/error_beep.mp3";
import ActionDialog from "@/components/dialogs/ActionDialog";
import TempAlert from "@/components/alerts/TempAlert";
export default {
  components: {
    ActionDialog,
    TempAlert,
  },
  data() {
    return {
      mode_dialog: false,
      mode: this.$store.getters.getScanningMode,
      sales_order_number: "",
      loading: false,
      selected_input: "sales_order_number_input",
      error_audio: new Audio(error_beep),
      success_audio: new Audio(success_beep),
      list_max_height: 0,
      can_select: true,
      blur_by_code: false
    };
  },
  beforeDestroy() {
    navigator.virtualKeyboard.overlaysContent = false;
  },
  created() {
    navigator.virtualKeyboard.overlaysContent = true;
    document.addEventListener("keydown", this.scanPressed);
    document.addEventListener("keyup", this.scanReleased);
    document.addEventListener("keypress", this.selectInput);
    document.addEventListener("paste", this.pasteInput);
  },
  mounted() {
    // Calculate the max height of the list card
    let height = window.innerHeight - 20;
    height -= document.getElementById("header").clientHeight;
    height -= document.getElementById("input").clientHeight;
    height -= document.getElementById("alert").clientHeight;
    height -= document.getElementById("list").clientHeight;
    height -= document.getElementById("action").clientHeight;
    this.list_max_height = height;
    
    // Get user to choose scanning type
    if (this.$router.options.history.state.back === "/" || this.mode === "") {
      this.mode_dialog = true;
    }

    // On page load ensure orders and items are cleared
    this.$store.commit("setSalesOrderNumbers", []);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.scanPressed);
    document.removeEventListener("keyup", this.scanReleased);
    document.removeEventListener("keypress", this.selectInput);
    document.removeEventListener("paste", this.pasteInput);
  },
  computed: {
    height() {
      return `max-height: ${this.list_max_height}px`;
    },
    sales_order_numbers() {
      return this.$store.getters.getSalesOrderNumbers;
    },
  },
  methods: {
    hideKeyboard() {
      // Only Hidekeyboard if Blur was handled
      // by clicking out of input field.
      if (!this.blur_by_code) {
        const inputField = document.getElementById("hide_keyboard");
        setTimeout(function() {
          inputField.setAttribute('style', 'display:block;');
          inputField.focus();
          setTimeout(function() {
            inputField.setAttribute('style', 'display:none;');
          }, 10);
        }, 10);
      }
    },
    scanPressed(e) {
      // If key pressed is the 'scanner' button
      if (e.key === "Unidentified" && e.code === "" && e.keyCode === 0 && this.can_select) {
        this.can_select = false;
        this.selectInput(e);
      }
    },
    scanReleased(e) {
      // If key pressed is the 'scanner' button
      if (e.key === "Unidentified" && e.code === "" && e.keyCode === 0) {
        this.can_select = true;
      }
    },
    setMode(mode) {
      this.mode = mode;
      this.$store.commit("setScanningMode", mode);
      this.mode_dialog = false;
      navigator.vibrate(100);
    },
    selectInput(e) {
      if (e.target.tagName !== "INPUT" || e.target.id !== this.selected_input) {
        var input = document.getElementById(this.selected_input);
        input.focus();
        if (!["Enter", "Unidentified"].includes(e.key)) {
          input._assign(input.value + e.key);
        }
        e.preventDefault();
      }
    },
    deselectInput() {
      var input = document.getElementById(this.selected_input);
      this.blur_by_code = true;
      input.blur();
      this.blur_by_code = false;
    },
    pasteInput(e) {
      if (e.target.tagName !== "INPUT" || e.target.id !== this.selected_input) {
        var input = document.getElementById(this.selected_input);
        let paste = (e.clipboardData || window.clipboardData).getData("text");
        input._assign(paste);
        this.onEnter();
        e.preventDefault();
      }
    },
    onEnter() {
      if (this.sales_order_number !== "") {
        if (this.sales_order_numbers.includes(this.sales_order_number)) {
          this.error_audio.play();
          this.$refs.alert.showAlert({ text: "Sales Order already selected" });
        } else {
          // this.success_audio.play();
          this.$store.commit("addSalesOrderNumber", this.sales_order_number);
          if (this.mode === "single_pick") {
            this.onConfirm();
          }
        }
        this.sales_order_number = "";
        this.deselectInput();
      }
    },
    async onConfirm() {
      navigator.vibrate(100);
      this.$store.commit("clearSalesOrderItems");
      this.loading = true;
      try {
        await this.$store.dispatch("getPickLists", this.sales_order_numbers);
        // If any items on the order have the old serialization still in place
        if (this.$store.getters.getRequiresFulfillment && !this.$store.getters.getUserDetails.generate_fulfillment) await this.$refs.fulfullment_required_dialog.showDialog();
        this.$router.push("/scan-pick/pick");
      } catch (err) {
        if ("code" in err && err.code === "ERR_NETWORK") {
          this.$refs.alert.showAlert({ text: err.message });
        }
        if (err.type === "partial") {
          this.$refs.error_dialog.showDialog({ message: err.message })
            .then(() => {
              this.$router.push("/scan-pick/pick");
            })
            .catch(() => {});
        } else if (err.type === "complete") {
          this.$refs.alert.showAlert({ text: err.message });
          // If there is a complete error remove all Sales Order Numbers to start again
          this.$store.commit("setSalesOrderNumbers", []);
        }
      }
      this.loading = false;
    },
    onClear() {
      this.$store.commit("setSalesOrderNumbers", []);
    },
  },
};
</script>
