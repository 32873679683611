import Axios from "axios";

export default {
  state: {
    sales_order_numbers: localStorage.getItem("sales_order_numbers") || "[]",
    picklist_items: [],
    picklist_items_map: {},
    serialized_items: [],
    picklist_item_serial_numbers: {},
    picked_items: [],
    scanning_mode: "",
    loading: false,
    ship_complete: false,
    requires_fulfillment: false
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status
    },
    setScanningMode(state, mode) {
      state.scanning_mode = mode;
    },
    setSalesOrderNumbers(state, numbers) {
      state.sales_order_numbers = JSON.stringify(numbers);
      localStorage.setItem("sales_order_numbers", JSON.stringify(numbers));
    },
    addSalesOrderNumber(state, number) {
      let sales_order_numbers = JSON.parse(state.sales_order_numbers);
      sales_order_numbers.push(number);
      state.sales_order_numbers = JSON.stringify(sales_order_numbers);
      localStorage.setItem("sales_order_numbers", JSON.stringify(sales_order_numbers));
    },
    removeSalesOrderNumber(state, index) {
      let sales_order_numbers = JSON.parse(state.sales_order_numbers);
      sales_order_numbers.splice(index, 1);
      state.sales_order_numbers = JSON.stringify(sales_order_numbers);
      localStorage.setItem("sales_order_numbers", JSON.stringify(sales_order_numbers));
    },
    clearSalesOrderItems(state) {
      state.picklist_items = [];
      state.picklist_items_map = {};
      state.serialized_items = [];
      state.picklist_item_serial_numbers = {};
      state.picked_items = [];
      state.ship_complete = false;
      state.requires_fulfillment = false;
    },
    addPicklistItem(state, item) {
      state.picklist_items.push(item);
    },
    setPicklistItemMap(state, payload) {
      state.picklist_items_map[payload.key] = payload.value;
    },
    addSerializedItem(state, item) {
      state.serialized_items.push(item);
    },
    addPickedItem(state, item) {
      state.picked_items.push(item);
    },
    setPicklistItemSerialNumbers(state, serialItems) {
      state.picklist_item_serial_numbers = serialItems;
    },
    setShipComplete(state, shipComplete) {
      state.ship_complete = shipComplete;
    },
    setRequiresFulfillment(state, requiresFulfillment) {
      state.requires_fulfillment = requiresFulfillment;
    }
  },
  actions: {
    reorderPicklistItems({ getters }) {
      const sortByPickPathLocation = (a, b) => {
        if (a.pickPathLocation === "") return 1;
        if (b.pickPathLocation === "") return -1;
        return a.pickPathLocation.localeCompare(b.pickPathLocation);
      };
      getters.getPicklistItems.sort((a, b) => {
        // If quantity picked greater than the amount to pick 
        // or item b is fully picked move up list
        if ((a.count > a.quantity && b.count <= b.quantity) || (b.count == b.quantity && a.count != a.quantity)) {
          return -1;
        }
        // If quantity picked equal to amount to pick 
        // or item b is over picked move down list
        if ((a.count == a.quantity && b.count != b.quantity) || (b.count > b.quantity && a.count <= a.quantity)) {
          return 1;
        }
        // Else sort by pickPathLocation
        return sortByPickPathLocation(a, b);
      });
    },
    async getPickLists({ commit, getters }) {
      await Axios.post(
        `${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/salesorders/get`,
        {
          salesOrderNumbers: getters.getSalesOrderNumbers,
        }
      )
        .then((result) => {
          if (result.data.success === false) {
            throw { type: "complete", message: result.data.error };
          }

          // Set the Sales Order Number to the Valid Numbers
          commit("setSalesOrderNumbers", result.data.salesOrders.valid);

          // Set the available serial numbers for the serialized picklist items
          commit("setPicklistItemSerialNumbers", result.data.serialItems);

          // Set Ship Complete order
          commit("setShipComplete", result.data.shipComplete)
          
          // Set if SalesOrder required fulfillment
          commit("setRequiresFulfillment", result.data.items.some((item) => item.old_serialized))

          if (result.data.salesOrders.invalid.length > 0 || result.data.items.length === 0) {
            throw { type: "partial", message: `Sales Order(s) have no items to pick: ${result.data.salesOrders.invalid.join(", ")}` };
          }
          // Iterate through all the items for the selected Sales Orders
          for (var item of result.data.items) {
            // If the item is uninitialized
            if (
              getters.getPicklistItem(item.sku) === undefined &&
              getters.getPicklistItem(item.barcode) === undefined
            ) {
              // Initialize a new picklist item
              let picklist_item = {
                name: item.name,
                sku: item.sku,
                barcode: item.barcode,
                count: 0,
                quantity: item.quantity,
                serialized: item.serialized,
                old_serialized: item.old_serialized, // TODO Remove this once old serialized items are not longer used
                salesOrderNumbers: Array(item.quantity).fill(item.salesOrderNumber),
                pickPathLocation: item.pickPathLocation,
                soh: item.soh,
                auBulkBin: item.auBulkBin,
              };
              // Add the item to the pick list items array
              commit("addPicklistItem", picklist_item);

              // Add the Sales Order item to the picklist items PolyMap
              commit("setPicklistItemMap", { key: item.sku, value: picklist_item });
              if (![null, undefined, "", "0", 0].includes(item.barcode)) {
                commit("setPicklistItemMap", { key: item.barcode, value: picklist_item });
                /**
                 * Work around for when barcodes are entered with or
                 * without a leading 0.
                 */
                if (item.barcode.startsWith("0")) {
                  // Remove the leading 0
                  commit("setPicklistItemMap", { key: item.barcode.substring(1), value: picklist_item });
                } else {
                  // Add leading 0
                  commit("setPicklistItemMap", { key: '0' + item.barcode, value: picklist_item });
                }
              }
            }
            // If the item already initialized
            else {
              // Increment quantity and add Sales Order Number to array
              getters.getPicklistItem(item.sku).quantity += item.quantity;
              for (let i = 0; i < item.quantity; i++) {
                getters.getPicklistItem(item.sku).salesOrderNumbers.push(item.salesOrderNumber);
              }
            }
          }
          // Once all items have been created reverse the salesOrderNumbers array, this is required as later we will be popping
          // from this array and it is more efficient to reverse once then pop as apposed to using shift. Additionally we will
          // sort the array first so that when picking is done it will attempt to do one whole SO at a time.
          getters.getPicklistItems.forEach(item => {
            item.salesOrderNumbers = item.salesOrderNumbers.sort().reverse();
          });
        })
        .catch((err) => {
          console.log(err)
          throw err;
        });
    },
    async createItemFulfillments({ getters }) {
      console.log(getters.getPickedItems)
      return await Axios.post(
        `${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/fulfillments/create`,
        getters.getPickedItems
      ).then((result) => {
        console.log("Result", result.data);
        return result.data;
      });
    },
  },
  getters: {
    getSalesOrderNumbers: (state) => JSON.parse(state.sales_order_numbers),
    getPicklistItem: (state) => (sku_barcode) =>
      state.picklist_items_map[sku_barcode],
    getPicklistItems: (state) => state.picklist_items,
    getPicklistItemsMap: (state) => state.picklist_items_map,
    getSerializedItems: (state) => state.serialized_items,
    getPicklistItemSerialNumbers: (state) => state.picklist_item_serial_numbers,
    getScanningMode: (state) => state.scanning_mode,
    getPickedItems: (state) => state.picked_items,
    getLoading: (state) => state.loading,
    getShipComplete: (state) => state.ship_complete,
    getRequiresFulfillment: (state) => state.requires_fulfillment
  },
};
