import { createStore } from "vuex";
import scanpick from "./scan-pick";
import images from "./images";
import user from "./user";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    scanpick: scanpick,
    images: images,
    user: user,
  },
});
