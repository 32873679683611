<template>
  <SelectSalesOrders />
</template>

<script>
import SelectSalesOrders from "@/components/SelectSalesOrders";
export default {
  components: {
    SelectSalesOrders,
  },
};
</script>
