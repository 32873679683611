<template>
  <v-container fluid class="pa-3" style="padding-top: 0px !important;">
    <v-switch
      v-model="generate_fulfillment"
      color="blue"
      hide-details
      :label="`Generate fulfillment on completion? (${generate_fulfillment ? 'Yes' : 'No'})`"
    >
    </v-switch>
  </v-container>
</template>

<script>
export default {
  name: "Generate Fulfillments",
  computed: {
    generate_fulfillment: {
      get: function () {
        return this.$store.getters.getUserDetails.generate_fulfillment;
      },
      set: async function(userGeneratesFulfillments) {
        const payload = {
          generate_fulfillment: userGeneratesFulfillments
        }
        await this.$store.dispatch("updateUserDetails", payload);
      }
    },
  },
};
</script>
