<template>
  <v-container fluid class="pa-3">
    <v-select
      v-model="user_location"
      :items="available_locations"
      item-title="name"
      item-value="id"
      label="Location"
    ></v-select>
  </v-container>
</template>

<script>
export default {
  name: "Location",
  data() {
    return {
    };
  },
  computed: {
    user_location: {
      get: function () {
        return this.$store.getters.getUserDetails.location?.toString();
      },
      set: async function(newLocation) {
        const payload = {
          location: parseInt(newLocation)
        }
        await this.$store.dispatch("updateUserDetails", payload);
      }
    },
    available_locations() {
      return this.$store.getters.getAvailableLocations;
    },
  },
  async mounted() {
    // Get the current users location and all available locations
    await this.$store.dispatch("fetchAvailableLocations");
  },
};
</script>
