<template>
  <v-container fluid class="pa-3">
    <v-row>
      <v-col align="center">
        <h1>Enter new password</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <p>This will be your new account password</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          id="newPasswordInput"
          ref="newPasswordInput"
          v-model="password"
          label="Password"
          placeholder="Please enter your password"
          variant="outlined"
          hide-details="auto"
          :rules="rules.password"
          :type="showPassword ? 'text' : 'password'"
          :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showPassword = !showPassword"
          @keyup.enter="onEnter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          id="newPasswordConfirmInput"
          ref="newPasswordConfirmInput"
          v-model="passwordConfirmation"
          label="Confirm password"
          placeholder="Please enter your password again"
          variant="outlined"
          hide-details="auto"
          :rules="rules.passwordConfirmation"
          :type="showPasswordConfirmation ? 'text' : 'password'"
          :append-inner-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showPasswordConfirmation = !showPasswordConfirmation"
          @keyup.enter="onEnter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row id="alert">
      <v-col>
        <TempAlert ref="alert" />
      </v-col>
    </v-row>
    <v-row class="action_button">
      <v-col align="center">
        <v-btn
          block
          size="large"
          :loading="loading"
          :disabled="!fieldsFilled"
          @click="resetPassword()"
        >
          Reset password
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TempAlert from "@/components/alerts/TempAlert";
export default {
  name: "LoginPage",
  components: {
    TempAlert,
  },
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      loading: false,
      showPassword: false,
      showPasswordConfirmation: false,
      rules: {
        password: [
          value => !!value || 'Password is required',
          value => (value || '').length >= 8 || 'Password must be at least 8 characters',
          value => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,])[A-Za-z\d!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,]{8,}$/.test(value) || 'Password must contain at least one letter, number and special character'
        ],
        passwordConfirmation: [
          value => !!value || "Password confirmation is required",
          () => this.password === this.passwordConfirmation || "Passwords do not match",
        ]
      }
    };
  },
  mounted() {
    this.$refs.newPasswordInput.focus();
  },
  computed: {
    fieldsFilled() {
      if (!this.password || !this.passwordConfirmation) {
        return false;
      }
      if (this.password !== this.passwordConfirmation) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onEnter() {
      if (this.fieldsFilled) {
        this.resetPassword();
      }
    },
    async resetPassword() {
      this.loading = true;
      try {
        const payload = {
          token: this.$route.params.token,
          password: this.password,
        }
        await this.$store.dispatch("resetPassword", payload);
        this.$router.push("/login");
      } catch (err) {
        if (err.code === "ERR_NETWORK") {
          err.message = "Sorry, there was a problem connecting to the network.";
        } else if (err.code === "ERR_BAD_REQUEST") {
          err.message = "Sorry, an unexpected error has occurred.";
        }
        this.$refs.alert.showAlert({ text: err.message });
      }
      this.loading = false;
    },
  },
};
</script>
