<template>
  <v-container fluid class="pa-3">
    <v-chip>
      User: {{ user_name }}
    </v-chip>
  </v-container>
  <Location />
  <GenerateFulfillments />
</template>

<script>
import Location from "@/components/profile/Location.vue";
import GenerateFulfillments from "@/components/profile/GenerateFulfillments.vue";

export default {
  components: {
    Location,
    GenerateFulfillments,
  },
  async mounted() {
    await this.$store.dispatch("fetchUserDetails");
  },
  computed: {
    user_name() {
      return this.$store.getters.getUserDetails.full_name;
    }
  }
};
</script>
