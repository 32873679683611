<template>
  <MainMenu />
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";

export default {
  components: {
    MainMenu,
  },
};
</script>
