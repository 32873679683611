<template>
  <ResetPasswordToken />
</template>

<script>
import ResetPasswordToken from "@/components/ResetPasswordToken.vue";

export default {
  components: {
    ResetPasswordToken,
  },
};
</script>
