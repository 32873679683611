<template>
  <v-container fluid class="pa-3">
    <!-- TODO REMOVE THIS ACTION DIALOG ONCE NO LONGER USING OLD SERIALIZED PRODUCTS -->
    <ActionDialog
      ref="old_serial_dialog"
      title="Enter Serial Number"
      max_width="90%"
      color="black"
      persistent
      cancel_text="Cancel Item Pick"
    >
      <template #default="{ options }">
        <v-text-field
          id="old_serial_number_input"
          v-model="options.data.serial_number"
          label="Serial Number"
          placeholder="Please enter a serial number"
          variant="outlined"
          hide-details="auto"
          clearable
          persistent-clearable
          :rules="[
            (v) => !!v || 'Required',
            (v) => v !== 'Enter' || 'Invalid serial number',
          ]"
          @keyup.enter="deselectInput(); options.confirm(options.data);"
        ></v-text-field>
      </template>
    </ActionDialog>
    <ActionDialog
      ref="serial_dialog"
      title="Enter Serial Number"
      max_width="90%"
      color="black"
      persistent
      cancel_text="Cancel Item Pick"
    >
      <template #default="{ options }">
        <v-text-field
          id="serial_number_input"
          v-model="options.data.serial_number"
          label="Serial Number"
          placeholder="Please enter a serial number"
          variant="outlined"
          hide-details="auto"
          clearable
          persistent-clearable
          :rules="[
            (v) => !!v || 'Required',
            (v) => (v !== 'Enter' && available_serial_numbers.includes(v)) || 'Invalid serial number',
          ]"
          @keyup.enter="deselectInput(); options.confirm(options.data);"
        ></v-text-field>
      </template>
    </ActionDialog>
    <ActionDialog
      ref="serial_item_removed"
      title="Serialized Item Removed"
      max_width="90%"
      persistent
      confirm_text="Continue"
      :cancellable="false"
    >
      <template #default="{ options }">
        <p v-html="options.data.message"></p>
      </template>
    </ActionDialog>
    <v-row>
      <v-col style="position: absolute">
        <v-btn icon variant="outlined" @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col align="center">
        <h1>Edit Item</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="item !== undefined">
          <p class="px-4 py-2 text-h6">{{ item.name }}</p>
          <v-divider />
          <v-card-text class="pa-0">
            <v-img
              :src="$store.getters.getProductImage(item.sku)"
              max-height="150"
            ></v-img>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col align="center">
                <label>SKU</label><br />
                <b>{{ item.sku }}</b>
              </v-col>
              <v-col align="center">
                <label>Barcode</label><br />
                <b>{{ item.barcode }}</b>
              </v-col>
              <v-col align="center">
                <label>Pick Quantity</label><br />
                <b>{{ item.quantity }}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <label>Pick Path Location</label><br />
                <b>{{ item.pickPathLocation != "" ? item.pickPathLocation : "N/A" }}</b>
              </v-col>
              <v-col align="center">
                <label>SOH</label><br />
                <b>{{ item.soh }}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <label>AU Bulk Location</label><br />
                <b>{{ item.auBulkBin != "" ? item.auBulkBin : "N/A" }}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn
                  icon
                  size="large"
                  @click="onDecrement"
                  :disabled="item.count === 0"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="item.count"
                  id="product_count"
                  type="number"
                  variant="outlined"
                  @input="debounceCountChange(item.count)"
                  @blur="() => {if (item.count == '') item.count = 0}"
                  ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn icon size="large" @click="onIncrement">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActionDialog from "@/components/dialogs/ActionDialog";
import _debounce from 'lodash/debounce';
import notify_beep from "@/assets/notify_beep.mp3";
export default {
  components: {
    ActionDialog,
  },
  data() {
    return {
      loading: false,
      selected_input: "product_count",
      notify_audio: new Audio(notify_beep),
    };
  },
  computed: {
    record_serial_number() {
      return this.$store.getters.getRequiresFulfillment || this.$store.getters.getUserDetails.generate_fulfillment;
    },
    item() {
      return this.$store.getters.getPicklistItem(this.$route.params.sku_barcode);
    },
    itemCount() {
      return this.item.count;
    },
    available_serial_numbers() {
      return this.$store.getters.getPicklistItemSerialNumbers[this.item.sku];
    },
    pickedItems() {
      return this.$store.getters.getPickedItems;
    }
  },
  watch: {
    async itemCount(newCount, oldCount) {
      // TODO CHANGE BACK TO SINGLE CHECK ONCE NO LONGER USING OLD SERIALIZED
      // if (!this.item.serialized) {
      if (!(this.item.serialized || this.item.old_serialized)) {
        let difference = newCount - oldCount;
        // Perform decrements based on difference
        for (let i = 0; i < Math.abs(Math.min(difference, 0)); i++) {
          await this.performDecrementTasks();
          // Peform small delay once tasks complete if not last item
          if (this.item.serialized && i != Math.max(difference, 0)) {
            await new Promise(r => setTimeout(r, 500));
          }
        }
        // Perform incremements based on difference
        for (let i = 0; i < Math.max(difference, 0); i++) {
          await this.performIncrementTasks();
          // Peform small delay once tasks complete if not last item
          if (this.item.serialized && i != Math.max(difference, 0)) {
            await new Promise(r => setTimeout(r, 500));
          }
        }
        // Reorder List if item is fully picked, over picked or was fully picked
        if (newCount == this.item.quantity || newCount > this.item.quantity || newCount + 1 == this.item.quantity) {
          this.$store.dispatch('reorderPicklistItems')
        }
      }
    }
  },
  mounted() {
    navigator.virtualKeyboard.overlaysContent = false;
    // If page reloaded, refetch the item data
    this.$nextTick(async () => {
      if (this.item === undefined) {
        this.loading = true;
        await this.$store.dispatch("getPickLists");
        this.loading = false;
      }
      this.item = this.$store.getters.getPicklistItem(this.$route.params.sku_barcode);
    });
  },
  methods: {
    debounceCountChange: _debounce(async function(count) {
      this.deselectInput()
      // TODO CHANGE BACK TO SINGLE CHECK ONCE NO LONGER USING OLD SERIALIZED
      // If is serialized item
      // if (this.item.serialized) {
      if (this.item.serialized || this.item.old_serialized) {
        // Get number of currently serialized for this item
        // Get the difference
        let difference = count - this.pickedItems.filter(item => item.sku == this.item.sku).length;

        // Perform decrements based on difference
        for (let i = 0; i < Math.abs(Math.min(difference, 0)); i++) {
          await this.performDecrementTasks();
          // Peform small delay once tasks complete if not last item
          if (this.item.serialized && i != Math.abs(Math.min(difference, 0))) {
            await new Promise(r => setTimeout(r, 500));
          }
        }
        // Perform incremements based on difference
        for (let i = 0; i < Math.max(difference, 0); i++) {
          let increment_result = await this.performIncrementTasks();
          // Increment was canceled (serialized cancel)
          if (increment_result === false) {
            this.item.count--;
          }
          // Peform small delay once tasks complete if not last item
          if (this.item.serialized && i != Math.max(difference, 0)) {
            await new Promise(r => setTimeout(r, 500));
          }
        }
        // Reorder List if item is fully picked, over picked or was fully picked
        if (count == this.item.quantity || count > this.item.quantity || count + 1 == this.item.quantity) {
          this.$store.dispatch('reorderPicklistItems')
        }

      }
      if (count == '') this.item.count = 0;
    }, 800),
    goBack() {
      this.$router.push('/scan-pick/pick');
      navigator.vibrate(100);
    },
    selectInput(e) {
      if (e.target.tagName !== "INPUT" || e.target.id !== this.selected_input) {
        var input = document.getElementById(this.selected_input);
        input.focus();
        if (!["Enter", "Unidentified"].includes(e.key)) {
          input._assign(input.value + e.key);
        }
        e.preventDefault();
      }
    },
    deselectInput() {
      var input = document.getElementById(this.selected_input);
      input.blur();
    },
    openSerialNumberDialog() {
      this.selected_input = 'serial_number_input'
      document.addEventListener("keypress", this.selectInput);
      // Open the serial number dialog
      return this.$refs.serial_dialog
        .showDialog({ serial_number: "" })
        .then((data) => {
          this.selected_input = 'product_count'
          document.removeEventListener("keypress", this.selectInput);
          return data.serial_number;
        }).catch(() => {
          this.selected_input = 'product_count'
          document.removeEventListener("keypress", this.selectInput);
          return;
        });
    },
    // TODO REMOVE BELOW ONCE NO LONGER USING OLD SERIALIZED PRODUCTS
    openOldSerialNumberDialog() {
      this.selected_input = 'old_serial_number_input'
      document.addEventListener("keypress", this.selectInput);
      // Open the serial number dialog
      return this.$refs.old_serial_dialog
        .showDialog({ serial_number: "" })
        .then((data) => {
          this.selected_input = 'product_count'
          document.removeEventListener("keypress", this.selectInput);
          return data.serial_number;
        }).catch(() => {
          this.selected_input = 'product_count'
          document.removeEventListener("keypress", this.selectInput);
          return;
        });
    },
    async performIncrementTasks() {
      let picked_item = {
        sku: this.item.sku,
        barcode: this.item.barcode,
        serial_number: null,
        old_serial_number: null, // TODO REMOVE ONCE NO LONGER USING OLD SERIALIZED
      }
      if (this.record_serial_number) {
        if (this.item.serialized) {
          // Open the serial number dialog and return the serial number
          this.notify_audio.play();
          let serial_number = await this.openSerialNumberDialog();
          this.notify_audio.load();
          if (serial_number === undefined) {
            return false;
          }
          picked_item.serial_number = serial_number;
  
          // Remove serial number from available serial numbers
          this.$store.getters.getPicklistItemSerialNumbers[this.item.sku] = this.$store.getters.getPicklistItemSerialNumbers[this.item.sku].filter((serial_number) => serial_number !== picked_item.serial_number);
        } // TODO REMOVE ONCE NO LONGER USING OLD SERIALIZED
         else if (this.item.old_serialized) {
          // Open the serial number dialog and return the serial number
          this.notify_audio.play();
          let serial_number = await this.openOldSerialNumberDialog();
          this.notify_audio.load();
          if (serial_number === undefined) {
            return false;
          }
          picked_item.old_serial_number = serial_number;
        }
      }
      picked_item.sales_order = this.item.salesOrderNumbers.pop()
      this.$store.commit("addPickedItem", picked_item);
    },
    async onIncrement() {
      navigator.vibrate(100);
      // TODO CHANGE BACK TO SINGLE CHECK ONCE NO LONGER USING OLD SERIALIZED
      // Perform increment manually as watcher does not get called
      // if (this.item.serialized) {
      if ((this.item.serialized || this.item.old_serialized) && this.record_serial_number) {
        if (await this.performIncrementTasks() !== false) {
          this.item.count++;
        }
      } else {
        this.item.count++;
      }
    },
    findLastItemOccurance(picked_items, item_sku) {
      for (let i = picked_items.length - 1; i >= 0; i--) {
        if (picked_items[i].sku === item_sku) {
          return i;
        }
      }
    },
    async performDecrementTasks(showDialog = true) {
      // Find the last picked item that has this sku.
      let item_index = this.findLastItemOccurance(this.$store.getters.getPickedItems, this.item.sku);
      // Remove from picked item array
      let picked_item = this.$store.getters.getPickedItems.splice(item_index, 1)[0];
      // Add Sales Order number back to item salesOrderNumbers array if it is not null
      if (picked_item.sales_order) this.item.salesOrderNumbers.push(picked_item.sales_order);
      // If item was serialized, add the Serial Number back to the available serial numbers
      if (this.item.serialized && showDialog && this.record_serial_number) {
        this.$store.getters.getPicklistItemSerialNumbers[this.item.sku].push(picked_item.serial_number);
        // Notify the user 
        await this.$refs.serial_item_removed.showDialog({ message: 
          `<b>Serial Number</b>: ${picked_item.serial_number}<br/><b>SKU</b>: ${this.item.sku}<br/><b>Sales Order Number</b>: ${ picked_item.sales_order ? picked_item.sales_order : 'N/A'}<br/><br/>
            <i>You must remove this item from your packed items before continuing.<i/>
          `
        })
      }
    },
    onDecrement() {
      if (this.item.count > 0) {
        navigator.vibrate(100);
        // Perform decrement manually as watcher does not get called
        if (this.item.serialized && this.record_serial_number) {
          this.performDecrementTasks();
        }
        this.item.count--;
      }
    },
  },
};
</script>
