<template>
  <v-app>
    <input type="text" id="hide_keyboard" style="display:none;">
    <v-overlay v-model="this.$store.getters.getLoading" persistent class="align-center justify-center" scrim="#ededeb">
      <v-progress-circular
        :size="120"
        :width="17"
        color="#24a9e1"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <AppBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
export default {
  name: "App",
  components: {
    AppBar,
  },
  setup() {
    if (localStorage.getItem("sales_order_numbers") === null) {
      localStorage.setItem("sales_order_numbers", "[]");
    }
  },
};
</script>
<style>
html {
    overscroll-behavior: none;
}
</style>
<style>
.v-theme--light.v-btn {
  border: thin solid rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px;
}
.v-theme--light.v-card {
  border: thin solid rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px;
}
.action_button {
  position: fixed;
  bottom: 12px;
  width: 100%;
}
.image-border {
  border: thin solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
</style>
