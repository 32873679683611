import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles"; // Global CSS has to be imported
import "./registerServiceWorker";
import { createApp } from "vue";
import { createVuetify } from "vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

axios.interceptors.request.use((config) => {
  // Set loading true
  store.commit("setLoading", true);
  // Set Authorization header
  config.headers.Authorization = `ScannerToken ${store.getters.getToken}`;
  return config;
});

axios.interceptors.response.use((response) => {
  // Stop loading screen
  store.commit("setLoading", false);
  return response;
},
(error) => {
  // Stop loading screen
  store.commit("setLoading", false);
  // If response undefined, is a network error
  if (!error.response) {
    return Promise.reject(error);
  }
  // If 401 response invalidate user
  if (error.response.status === 401) {
    store.commit("invalidateToken");
    router.push("/login");
  }
  return Promise.reject(error);
});

const app = createApp(App);
const vuetify = createVuetify({
  components,
  directives,
});

app.use(vuetify);
app.use(router);
app.use(store);

app.mount("#app");
