<template>
  <v-container fluid class="pa-3">
    <v-row>
      <v-col align="center">
        <h1>Login</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          id="username_input"
          ref="username_input"
          v-model="login_details.username"
          label="Username"
          placeholder="Please enter your username"
          variant="outlined"
          hide-details
          @keyup.enter="onEnter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          id="password_input"
          v-model="login_details.password"
          label="Password"
          placeholder="Please enter your password"
          variant="outlined"
          hide-details
          :type="show_password ? 'text' : 'password'"
          :append-inner-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="show_password = !show_password"
          @keyup.enter="onEnter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row id="alert">
      <v-col>
        <TempAlert ref="alert" />
      </v-col>
    </v-row>
    <v-row class="action_button pb-14">
      <v-col align="center">
        <v-btn
          block
          size="large"
          @click="$router.push('/reset-password')"
        >
          Forgot password
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="action_button">
      <v-col align="center">
        <v-btn
          block
          size="large"
          :loading="loading"
          :disabled="!fieldsFilled"
          @click="login()"
        >
          Login
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TempAlert from "@/components/alerts/TempAlert";
export default {
  name: "LoginPage",
  components: {
    TempAlert,
  },
  data() {
    return {
      login_details: {
        username: "",
        password: "",
      },
      loading: false,
      show_password: false,
    };
  },
  mounted() {
    this.$refs.username_input.focus();
  },
  computed: {
    fieldsFilled() {
      if (!this.login_details.username || !this.login_details.password) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onEnter() {
      if (this.fieldsFilled) {
        this.login();
      }
    },
    async login() {
      this.loading = true;
      try {
        await this.$store.dispatch("login", this.login_details);
        this.$router.push("/");
      } catch (err) {
        if (err.code === "ERR_NETWORK") {
          err.message = "Sorry, there was a problem connecting to the network.";
        } else if (err.code === "ERR_BAD_REQUEST") {
          err.message = "Sorry, please enter a valid username and password.";
        }
        this.$refs.alert.showAlert({ text: err.message });
      }
      this.loading = false;
    },
  },
};
</script>
