<template>
  <EditSalesOrderItem />
</template>

<script>
import EditSalesOrderItem from "@/components/EditSalesOrderItem";
export default {
  components: {
    EditSalesOrderItem,
  },
};
</script>
