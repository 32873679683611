import Axios from "axios";

export default {
  state: {
    product_images: localStorage.getItem("product_images") || "{}",
  },
  mutations: {
    setProductImages(state, images) {
      state.product_images = JSON.stringify(images);
      localStorage.setItem("product_images", JSON.stringify(images));
    },
  },
  actions: {
    async getProductImages({ commit }) {
      await Axios.get(`${process.env.VUE_APP_INTEGRATIONS_BASE_URL}/images`)
        .then((result) => {
          commit("setProductImages", result.data.images);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  getters: {
    getProductImage: (state) => (sku_barcode) =>
      JSON.parse(state.product_images)[sku_barcode],
  },
};
